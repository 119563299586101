import { createSlice } from '@reduxjs/toolkit';
import { ChatMessage } from 'shared-interfaces';
import { chatApi } from '../api';
import { RootState } from '../store';
// import * as Notifications from 'expo-notifications';

// State Interface
export interface ChatState {
  conversation: ChatMessage[];
  typingIndicators: string[];
  onlineStatus: Record<string, boolean>;
  connected: boolean;
  latestSeenMessage: number | null;
  unread: number;
}

// Initial State
export const initialConversationState: ChatState = {
  conversation: [],
  typingIndicators: [],
  onlineStatus: {},
  connected: false,
  latestSeenMessage: null,
  unread: 0,
};

// Slice
export const chatSlice = createSlice({
  name: 'chat',
  initialState: initialConversationState,
  reducers: {
    addTemporaryMessage(state, action) {
      const { message } = action.payload;
      state.conversation = [...state.conversation, message];
    },
    addMessage(state, action) {
      const message = action.payload;
      if (state.conversation.some((m) => m.tempId === message.tempId)) {
        state.conversation = state.conversation.map((m) => {
          if (m.tempId === message.tempId) return message;
          return m;
        });
        return;
      }
      state.conversation = [...state.conversation, message];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(chatApi.endpoints.chatGetConversation.matchFulfilled, (state, action) => {
      const { messages } = action.payload;
      state.conversation = messages;
    });
  },
});

// Actions
export const { addMessage, addTemporaryMessage } = chatSlice.actions;

// Selectors
export const getConversation = (state: RootState): ChatMessage[] => state.chat.conversation;
export const getChatBadgeCount = (state: RootState): number | null =>
  state.chat.conversation.filter((m) => !m.readAt && m.staffId !== null).length;

// Reducer
export default chatSlice.reducer;
