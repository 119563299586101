import React from "react";
import styled from "styled-components";
import Paragraph from "../texts/Paragraph";

// Styled Components
const FooterContainer = styled.footer`
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  padding: 1.5rem 1rem;
  background-color: #145e80;
  color: #ffffff;

  .link {
    color: #ffffff;

    &:not(.disabled) {
      &:hover,
      &:focus {
        color: #d8d8d8;
      }

      &:active {
        color: #f41c5e;
        text-decoration: none;
      }
    }
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Footer = () => (
  <FooterContainer>
    <Container>
      <div style={{ maxWidth: 650 }}>
        <Paragraph color="white" size="tiny" leftAlign>
          Ascenti Physio Limited and Ascenti Health Limited are members of the
          Ascenti Group and trade under the Ascenti brand. Incorporated in
          England &amp; Wales | Company numbers: 04530716 and 03225768 |
          Registered office: Carnac House, Cams Hall Estate, Fareham PO16 8UZ |
          &nbsp;
          <a
            href="https://www.ascenti.co.uk"
            className="link"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            www.ascenti.co.uk
          </a>
        </Paragraph>
      </div>
    </Container>
  </FooterContainer>
);

export default Footer;
