import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useAssessmentCreateMedicalProfileMutation,
  getAssessmentMedicalProfile,
  getUser,
  setMedicalProfileDateOfBirth,
  setMedicalProfileFirstName,
  setMedicalProfileLastName,
  useAppSelector,
} from "shared-redux";
import {
  DATE_OF_BIRTH_VALIDATOR,
  FIRST_NAME_VALIDATOR,
  LAST_NAME_VALIDATOR,
  t,
  theme,
  useValidator,
} from "shared-utilities";
import styled from "styled-components";
import CloseButton from "../assets/svg/buttons/CloseButton";
import { DeleteAnswersModal } from "../components/assessment/DeleteAnswersModal";
import RoundButton from "../components/buttons/RoundButton";
import DateInputField from "../components/inputs/DateInputField";
import TextInputField from "../components/inputs/TextInputField";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";
import Form from "../components/patterns/Form";
import { Assessment } from "./Assessment";

const CertInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-right: 2px;
  margin-top: ${theme.spacingPx(4)};
  margin-bottom: ${theme.spacingPx(4)};
  .hcpc-logo {
    width: 32px;
    height: 27px;
  }
`;

export const LastStepScreen = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useAppSelector(getUser);

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = React.useState<boolean>(false);

  // Store
  const { firstName, lastName, dateOfBirth } = useAppSelector(
    getAssessmentMedicalProfile
  );
  const dispatchSetFirstName = (value: string) =>
    dispatch(setMedicalProfileFirstName(value));
  const dispatchSetLastName = (value: string) =>
    dispatch(setMedicalProfileLastName(value));
  const dispatchSetDateOfBirth = (value: string) =>
    dispatch(setMedicalProfileDateOfBirth(value));
  const [dispatchMedicalProfile, { isLoading, error, isSuccess }] =
    useAssessmentCreateMedicalProfileMutation();

  // Form
  const firstNameError = useValidator([FIRST_NAME_VALIDATOR], firstName.trim());
  const lastNameError = useValidator([LAST_NAME_VALIDATOR], lastName.trim());
  const dateOfBirthError = useValidator([DATE_OF_BIRTH_VALIDATOR], dateOfBirth);

  // Computed Variables
  const isDateValid =
    new Date(dateOfBirth).setHours(0, 0, 0, 0) <
    new Date().setHours(0, 0, 0, 0);
  const humanDateFormat = dateOfBirth
    ? moment(dateOfBirth).format("DD/MM/YYYY")
    : undefined;

  const openModalToggler = () => {
    setIsOpen(!isOpen);
  };
  const handleContinue = async () => {
    setDisplayErrors(true);
    // Validation
    if (firstNameError || lastNameError || dateOfBirthError) return;
    await dispatchMedicalProfile({ firstName, lastName, dateOfBirth });
  };

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatchSetFirstName(event.target.value);
    setDisplayErrors(false);
  };
  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchSetLastName(event.target.value);
    setDisplayErrors(false);
  };
  const handleDatePicked = (date: Date) => {
    dispatchSetDateOfBirth(date.toISOString());
    setDisplayErrors(false);
  };

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    window.history.pushState(null, window.location.pathname);
  };

  useEffect(() => {
    window.history.pushState(null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  // Effects
  React.useEffect(() => {
    const { firstName, lastName, medicalInformation } = user ?? {};
    const { dateOfBirth } = medicalInformation ?? {};
    if (firstName) dispatchSetFirstName(firstName);
    if (lastName) dispatchSetLastName(lastName);
    if (dateOfBirth) dispatchSetDateOfBirth(dateOfBirth);
  }, [user]);

  React.useEffect(() => {
    if (!error) return;
    console.log("error", error);
  }, [error]);

  React.useEffect(() => {
    if (!isSuccess) return;
    if (user?.referralType === "sso" && user.email)
      return navigate("/assessment-complete");
    navigate("/sign-up");
  }, [isSuccess]);

  return (
    <Assessment>
      <TopInfoBar
        title="Account Details"
        rightButton={<CloseButton />}
        rightButtonOnClick={openModalToggler}
        useAscentiLogo
      />
      {isOpen && <DeleteAnswersModal onClose={openModalToggler} />}
      <Form title={t("assessment_medical_profile_txt")}>
        <TextInputField
          placeholderKey="form_label_first_name"
          value={firstName}
          error={displayErrors && firstNameError}
          textContentType="givenName"
          autoCapitalize="words"
          returnKeyType="done"
          // ref={firstNameRef}
          onChangeText={handleChangeFirstName}
          // onSubmitEditing={lastNameRef.current?.focus}
          // autoFocus
          editable={!user?.firstName}
        />
        <TextInputField
          placeholderKey="form_label_last_name"
          value={lastName}
          error={displayErrors && lastNameError}
          textContentType="givenName"
          autoCapitalize="words"
          returnKeyType="done"
          // ref={firstNameRef}
          onChangeText={handleChangeLastName}
          // onSubmitEditing={lastNameRef.current?.focus}
          // autoFocus
          editable={!user?.lastName}
        />
        <DateInputField
          onDatePicked={handleDatePicked}
          placeholderKey="form_label_birthdate"
          value={humanDateFormat}
          error={displayErrors && dateOfBirthError}
        />
        <CertInfo>
          <img
            src={require("src/assets/hcpc_logo.png")}
            alt="hcpc logo"
            className="hcpc-logo"
          />
        </CertInfo>
        <RoundButton
          text={t("btn_create_profile")}
          onPress={handleContinue}
          disabled={!(!!firstName && !!lastName && isDateValid)}
          loading={isLoading}
        />
      </Form>
    </Assessment>
  );
};
