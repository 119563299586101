import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getReferralDetails, useAppSelector } from "shared-redux";
import AuthCheck from "./components/auth/AuthCheck";
import BaseLayout from "./components/layout/BaseLayout";
import {
  LaunchScreen,
  AuthHandover,
  SignUpScreen,
  LastStepScreen,
  AssessmentScreen,
  AssessmentFirstWorkoutScreen,
  AssessmentCommitmentScreen,
  AssessmentCompleteScreen,
  AssessmentReferralCodeScreen,
  AssessmentStaticQuestionsScreen,
  InstructionsOfUseQuestionScreenWpa,
  InstructionsOfUseQuestionScreen,
  DashboardLearnScreen,
  LearnArticleScreen,
  AuthLoginScreen,
  DashboardWorkoutScreen,
  WorkoutScreen,
  WorkoutIntroScreen,
  WorkoutDetailedExerciseScreen,
  WorkoutCountdownScreen,
  WorkoutTrophyPageScreen,
  WorkoutFeedbackScreen,
  WorkoutDetailedFeedbackScreen,
  DashboardChatWidgetScreen,
  WorkoutRestScreen,
  DashboardProgressScreen,
  DashboardProfileScreen,
  ProgressPhaseDescription,
  ProgressCheckinScreen,
  CheckinCompleteScreen,
} from "./screens";

function App() {
  const referralDetails = useAppSelector(getReferralDetails);
  const isWpa = referralDetails?.organisation === "WPA";

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/wpa" element={<AuthHandover />} />
        <Route path="/launch" element={<LaunchScreen />} />
        <Route path="/referral" element={<AssessmentReferralCodeScreen />} />
        <Route
          path="/assessment-complete"
          element={<AssessmentCompleteScreen />}
        />
        <Route path="/commitment" element={<AssessmentCommitmentScreen />} />
        <Route path="/symptoms" element={<AssessmentScreen />} />
        <Route
          path="/instructions"
          element={
            isWpa ? (
              <InstructionsOfUseQuestionScreenWpa />
            ) : (
              <InstructionsOfUseQuestionScreen />
            )
          }
        />
        <Route
          path="/first-workout"
          element={<AssessmentFirstWorkoutScreen />}
        />

        <Route
          path="/questions"
          element={<AssessmentStaticQuestionsScreen />}
        />
        <Route path="/sign-up" element={<SignUpScreen />} />
        <Route path="/last-step" element={<LastStepScreen />} />
        <Route path="/login" element={<AuthLoginScreen />} />

        <Route path="/workout/:workoutType">
          <Route path="intro" element={<WorkoutIntroScreen />} />
          <Route path="countdown" element={<WorkoutCountdownScreen />} />
          <Route path="session/:stepIndex">
            <Route path="exercise" element={<WorkoutScreen />} />
            <Route path="rest" element={<WorkoutRestScreen />} />
            {/* <Route path="feedback" element={<WorkoutFeedbackScreen />} /> */}
          </Route>
          <Route
            path="exercise/:exerciseId"
            element={<WorkoutDetailedExerciseScreen />}
          />
          <Route path="trophy" element={<WorkoutTrophyPageScreen />} />
          <Route path="feedback/:index?" element={<WorkoutFeedbackScreen />} />
          <Route
            path="feedback-detailed/:index"
            element={<WorkoutDetailedFeedbackScreen />}
          />
        </Route>

        <Route element={<AuthCheck />}>
          <Route path="/workout" element={<WorkoutIntroScreen />} />
          <Route
            path="/workout/exercise"
            element={<WorkoutDetailedExerciseScreen />}
          />
          <Route path="/test" element={<WorkoutCountdownScreen />} />
          <Route
            path="/progress/ProgressPhaseDescription"
            element={<ProgressPhaseDescription />}
          />
          <Route
            path="/progress/ProgressCheckin"
            element={<ProgressCheckinScreen />}
          />
          <Route
            path="/progress/CheckinCompleted"
            element={<CheckinCompleteScreen />}
          />
          <Route element={<BaseLayout />}>
            <Route path="/chat" element={<DashboardChatWidgetScreen />} />
            <Route path="/" element={<DashboardWorkoutScreen />} />
            <Route path="/learn" element={<DashboardLearnScreen />} />
            <Route path="/progress" element={<DashboardProgressScreen />} />
            <Route path="/learn/article" element={<LearnArticleScreen />} />
            <Route path="/profile" element={<DashboardProfileScreen />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
