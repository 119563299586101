import React from "react";
import { getInitials, getPersonalDetails, isPhysioAccount } from "shared-chat";
import { Account, IUser } from "shared-interfaces";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";

const Root = styled.div((props) => ({
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: props.color,
  overflow: "hidden",
  position: "relative",
}));
const Text = styled.div({
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: 16,
  color: theme.palette.GREY,
});
// TODO resizeMode cover
const Image = styled.img({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  objectFit: "cover",
  objectPosition: "center",
});

interface ChatAvatarProps {
  user: IUser | Account;
  size?: number;
  isPhysio?: boolean;
}

const ChatAvatar: React.FC<ChatAvatarProps> = ({
  user,
  size = 40,
  isPhysio = false,
}) => {
  // Computed Variables
  const { firstName, lastName } = getPersonalDetails(user);
  const initials = isPhysio ? "AR" : getInitials(firstName, lastName);
  const image = isPhysioAccount(user) ? user?.imageUrl : undefined;
  const color = isPhysioAccount(user)
    ? theme.palette.BACKGROUND
    : theme.palette.BACKGROUND;
  const rootStyles = { width: size, height: size, borderRadius: size / 2 };

  // Renderer
  const renderInner = () => {
    const label = (
      <Text css={theme.typography[theme.typography.FONT_NAME].SMALL_TEXT}>
        {initials}
      </Text>
    );
    if (!image) return label;
    return (
      <>
        {label}
        <Image height={size} width={size} src={image} />
      </>
    );
  };

  return (
    <Root color={color} css={rootStyles}>
      {renderInner()}
    </Root>
  );
};

export default ChatAvatar;
