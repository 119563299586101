// @TODO: Fix any types
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Answer,
  AssessmentSection,
  MainQuestionCondition,
  MultipleChoiceAnswer,
} from "shared-interfaces";
import {
  useAssessmentSubmitMutation,
  addAnswer,
  getAssessmentAnswers,
  getAssessmentId,
  getAssessmentMedicalProfile,
  getAssessmentSections,
  getQuestionConditions,
  getUser,
  useAppSelector,
} from "shared-redux";
import {
  t,
  createErrorResponse,
  getErrorString,
  theme,
  questionConditionPredicate,
} from "shared-utilities";
import { Button, AssessmentHeader } from "src/components";
import {
  YesNoQuestionView,
  ChoiceQuestionView,
  LongTextQuestionView,
  PainSliderQuestionView,
  DescriptionQuestionView,
  SectionIntroQuestionView,
  RedFlagWarningQuestionView,
  MultipleChoiceQuestionView,
  PainScaleQuestionView,
} from "src/components/assessment/questionTypes";
import styled from "styled-components/macro";
import useIsMobile from "../utilities/useIsMobile";
import { Assessment } from "./Assessment";

const Footer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  @media (min-width: 769px) {
    width: 40%;
    margin-bottom: 30px;
  }
`;

const MultiQuestionsWrapper = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  padding-bottom: 60px;
  padding-top: 60px;

  @media (min-width: 769px) {
    margin-top: 120px;
    padding-bottom: 20px;
  }
`;

const Text = styled.div``;

interface MultiQuestionsSectionViewProps {
  sections: AssessmentSection[];
  answers: any[];
  addAnswer: any;
  activeAnswer: Answer | null;
  setActiveAnswer: (answer: any) => void;
}

export const MultiQuestionsSectionView: React.FC<
  MultiQuestionsSectionViewProps
> = ({ sections, answers, addAnswer, activeAnswer, setActiveAnswer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const assessmentId = useAppSelector(getAssessmentId);
  const { firstName, lastName, dateOfBirth } = useAppSelector(
    getAssessmentMedicalProfile
  );
  const user = useAppSelector(getUser);
  const [
    dispatchSubmit,
    { isLoading: isLoadingAssessment, error, isSuccess: isSuccessAssessment },
  ] = useAssessmentSubmitMutation();
  const assessmentError = error as any;

  const [page, setPage] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeSubAnswer, setActiveSubAnswer] = useState<any>(null);
  const [nextButtonText, setNextButtonText] = useState<string>("Next");

  const activeSection = sections[page] || { questions: [] };
  const activeQuestion = activeSection.questions[activeStep] as any;

  const isFinalCheck =
    page === sections.length - 1 &&
    activeStep === activeSection.questions.length - 1;

  const activeQuestionSelectedAnswer = answers.find(
    (answer: any) => answer?.question === activeQuestion?.id
  );
  const activeAnswerOption = activeQuestion?.questionChoices?.find(
    (questionChoice: any) =>
      questionChoice.id === activeQuestionSelectedAnswer?.input?.value
  );

  const isAnswerWithSubFilled =
    activeAnswerOption && activeAnswerOption?.subQuestion
      ? !!activeSubAnswer
      : false;

  const showNextButton =
    activeQuestion.type === "section_intro" ||
    activeQuestion.type === "red_flag_warning";

  useEffect(() => {
    if (activeQuestion.button) setNextButtonText(activeQuestion.button);
    else if (
      activeQuestion.type === "multiple_choice" &&
      !activeQuestion.required
    ) {
      if (
        !activeAnswer ||
        !(activeAnswer.input as MultipleChoiceAnswer).value.length
      )
        setNextButtonText(t("btn_none_apply"));
      else setNextButtonText(t("btn_next"));
    } else setNextButtonText(t("btn_next"));
  }, [activeAnswer, activeQuestion]);

  const isLastQuestion = activeSection.questions.length === activeStep + 1;

  useEffect(() => {
    const activeSubQuestionSelectedAnswer = answers.find(
      (answer: any) => answer?.question === activeAnswerOption?.subQuestion?.id
    );

    if (!activeAnswer && activeQuestionSelectedAnswer) {
      setActiveAnswer(activeQuestionSelectedAnswer);
    }

    if (!activeSubAnswer && activeSubQuestionSelectedAnswer) {
      setActiveSubAnswer(activeSubQuestionSelectedAnswer);
    }
  }, [activeStep, page, answers, activeQuestion]);

  useEffect(() => {
    if (!isSuccessAssessment) return;
    if (firstName && lastName && dateOfBirth) {
      if (user?.referralType === "sso" && user.email)
        return navigate("/assessment-complete");
      return navigate("/sign-up");
    }
    return navigate("/last-step");
  }, [isSuccessAssessment]);

  useEffect(() => {
    if (!assessmentError) return;

    if ("data" in assessmentError) {
      const typedError = createErrorResponse(assessmentError);
      toast.error(
        `Error submitting assessment: ${getErrorString(
          typedError.data.message
        )}`
      );
    }
  }, [assessmentError]);

  const syncActiveStepWithUrl = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(urlParams.get("page") || "0", 10);
    const stepFromUrl = parseInt(urlParams.get("step") || "0", 10);
    setPage(pageFromUrl);
    setActiveStep(stepFromUrl);
  }, [location.search]);

  useEffect(() => {
    syncActiveStepWithUrl();
  }, [location.search, syncActiveStepWithUrl]);

  const updateActiveStepHandler = () => {
    const nextStep = isLastQuestion ? 0 : activeStep + 1;
    const nextPage = isLastQuestion ? page + 1 : page;
    setActiveStep(nextStep);
    setActiveAnswer(null);
    setActiveSubAnswer(null);

    navigate(`?page=${nextPage}&step=${nextStep}`, { replace: false });

    if (isLastQuestion) {
      setPage(nextPage);
    }
  };

  const selectQuestionsHandler = (id: number, type: string, value: any) => {
    if (type === "multiple_choice") {
      addAnswer({
        question: id,
        input: {
          type,
          value: value ?? [],
        },
      });
      setActiveAnswer({
        question: id,
        input: { type, value: value ?? [] },
      });
      return;
    }
    addAnswer({
      question: id,
      input: {
        type,
        value,
      },
    });
    setActiveAnswer({
      question: id,
      input: { type, value: value ?? null },
    });
  };

  const selectSubQuestionsHandler = (
    id: number,
    type: string,
    value: string
  ) => {
    addAnswer({
      question: id,
      input: { type, value },
    });
    setActiveSubAnswer({
      question: id,
      input: { type, value: value ?? null },
    });
  };

  const setUpdatedAnswers = (filteredAnswersList: any) => {
    if (activeAnswer) return [...filteredAnswersList, activeAnswer];
    if (activeQuestion.type === "multiple_choice") {
      return [
        ...filteredAnswersList,
        {
          question: activeQuestion.id,
          input: { type: activeQuestion.type, value: [] },
        },
      ];
    }
    return [...filteredAnswersList, activeAnswer];
  };

  const submitAnswersHandler = async () => {
    const { id, type } = activeQuestion;

    if (type === "description") {
      updateActiveStepHandler();
    }
    if (
      activeAnswer ||
      (type === "multiple_choice" && !activeQuestion.required)
    ) {
      const filteredAnswersList = answers?.filter(
        (item) => item?.question !== id
      );
      const updatedAnswers = setUpdatedAnswers(filteredAnswersList);

      if (activeSubAnswer) {
        updatedAnswers.push(activeSubAnswer);
      }

      if (isFinalCheck) {
        await dispatchSubmit({
          id: assessmentId as number,
          answers: updatedAnswers,
        });
      } else {
        updateActiveStepHandler();
      }
    }
  };

  const backNavigationHandler = () => {
    if (!activeStep) {
      const prevPage = page - 1;
      const prevStep = sections[prevPage].questions.length - 1;
      setPage(prevPage);
      setActiveStep(prevStep);
      navigate(`?page=${prevPage}&step=${prevStep}`, { replace: false });
    } else {
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
      navigate(`?page=${page}&step=${prevStep}`, { replace: false });
    }
  };

  const onBackButtonEvent = useCallback(
    (e: any) => {
      e.preventDefault();
      syncActiveStepWithUrl();
    },
    [syncActiveStepWithUrl]
  );

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [onBackButtonEvent]);

  const renderQuestionsList = activeSection?.questions
    .slice(activeStep, activeStep + 1)
    .map((item) => {
      switch (item?.type) {
        case "choice": {
          return (
            <ChoiceQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
              activeAnswerOption={activeAnswerOption}
              onChangeSubAnswer={selectSubQuestionsHandler}
            />
          );
        }

        case "description": {
          return <DescriptionQuestionView key={item.id} item={item} />;
        }

        case "multiple_choice": {
          return (
            <MultipleChoiceQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "short_text":
        case "long_text": {
          return (
            <LongTextQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "section_intro": {
          return (
            <SectionIntroQuestionView
              key={item.id}
              item={item}
              onChange={updateActiveStepHandler}
            />
          );
        }

        case "yes_no": {
          return (
            <YesNoQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "red_flag_warning": {
          return (
            <RedFlagWarningQuestionView
              key={item.id}
              onChange={updateActiveStepHandler}
            />
          );
        }

        case "pain_scale": {
          return (
            <PainScaleQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        case "pain_slider": {
          return (
            <PainSliderQuestionView
              key={item.id}
              item={item}
              answers={answers}
              onChange={selectQuestionsHandler}
            />
          );
        }

        default: {
          return null;
        }
      }
    });

  const activateNextButton = () => {
    if (activeAnswerOption?.subQuestion) {
      if (!!activeSubAnswer?.input.value === true) {
        return true;
      }
      return false;
    }
    return (
      !!activeAnswer ||
      isAnswerWithSubFilled ||
      activeQuestion.type === "description" ||
      activeQuestion.type === "multiple_choice"
    );
  };

  return (
    <>
      <AssessmentHeader
        page={page}
        onBack={backNavigationHandler}
        sections={sections}
        activeStep={activeStep}
      />
      <MultiQuestionsWrapper>{renderQuestionsList}</MultiQuestionsWrapper>
      {!showNextButton && (
        <Footer>
          <Button
            bgColor={theme.palette.PRIMARY}
            isFullWidth
            isRoundedCorners={!isMobile}
            loading={isLoadingAssessment}
            handleClick={submitAnswersHandler}
            isActive={activateNextButton()}
          >
            <Text
              css={[
                theme.typography[theme.typography.FONT_NAME]
                  .REGULAR_BOLD_HEADING,
                { color: theme.palette.WHITE },
              ]}
            >
              {nextButtonText}
            </Text>
          </Button>
        </Footer>
      )}
    </>
  );
};

export const AssessmentScreen = () => {
  const dispatch = useDispatch();

  const sections = useAppSelector(getAssessmentSections);
  const conditions = useAppSelector(getQuestionConditions);
  const [activeAnswer, setActiveAnswer] = useState<Answer | null>(null);
  const answers = useAppSelector(getAssessmentAnswers);

  const dispatchAddAnswer = (answer: Answer) => {
    dispatch(addAnswer(answer));
    setActiveAnswer(answer);
  };

  // Computed Variables
  const conditionMap = React.useMemo(
    () =>
      conditions.map((condition) => {
        const { questionId, conditions } = condition;
        return [questionId, conditions] as [number, MainQuestionCondition[]];
      }),
    [conditions]
  );

  const conditionPredicate = React.useMemo(
    () => questionConditionPredicate(conditionMap, answers),
    [conditionMap, answers]
  );

  const sectionsWithConditions = React.useMemo(
    () =>
      sections.reduce<AssessmentSection[]>((acc, section) => {
        const questions = section.questions.filter(conditionPredicate);
        if (!questions.length) return acc;
        return [...acc, { ...section, questions }];
      }, []),
    [sections, conditionPredicate]
  );

  return (
    <Assessment>
      <MultiQuestionsSectionView
        sections={sectionsWithConditions}
        answers={answers}
        addAnswer={dispatchAddAnswer}
        activeAnswer={activeAnswer}
        setActiveAnswer={setActiveAnswer}
      />
    </Assessment>
  );
};
