import React from "react";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";
import { ReactComponent as LogoSvg } from "../../../assets/ascenti-logo.svg";

interface TopContainerProps {
  useAscentiLogo: boolean;
}

const TopContainer = styled.div<TopContainerProps>`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 0;
  right: 0;
  background-color: ${theme.palette.TOP_BAR_BACKGROUND};
  z-index: 1;
  border-bottom: 2px solid #ebebeb;
  height: ${(props) => (props.useAscentiLogo ? "" : "56px")};

  @media (max-width: 768px) {
    height: 56px;
  }
`;

const Logo = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block;
    padding-bottom: 10px;
  }
`;

const Text = styled("div")({
  color: theme.palette.TOP_BAR_TEXT,
  margin: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const LeftButtonContainer = styled("div")({
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "0%",
  transform: "translate(50%, -50%)",
});

const LeftImageContainer = styled("div")({});

const RightButtonContainer = styled("div")({
  display: "flex",
  position: "absolute",
  top: "50%",
  right: "1%",
  transform: "translate(-50%, -50%)",
});

interface TopInfoBarProps {
  title?: string;
  image?: JSX.Element | null;
  leftButton?: React.ReactNode;
  leftButtonOnClick?: () => void;
  rightButton?: React.ReactNode;
  rightButtonOnClick?: () => void;
  useAscentiLogo?: boolean;
}

export const TopInfoBar: React.FC<TopInfoBarProps> = ({
  title,
  image,
  leftButton,
  leftButtonOnClick,
  rightButton,
  rightButtonOnClick,
  useAscentiLogo = false,
}) => {
  const renderLeftButton = () => {
    if (useAscentiLogo) {
      return (
        <LeftImageContainer onClick={leftButtonOnClick}>
          <Logo>
            <LogoSvg />
          </Logo>
        </LeftImageContainer>
      );
    }
    if (!leftButton) return null;
    return (
      <LeftButtonContainer onClick={leftButtonOnClick}>
        {leftButton}
      </LeftButtonContainer>
    );
  };

  const renderRightButton = () => {
    if (!rightButton) return null;
    return (
      <RightButtonContainer onClick={rightButtonOnClick}>
        {rightButton}
      </RightButtonContainer>
    );
  };

  const renderTitleOrImage = () => {
    if (title) {
      return (
        <Text
          css={
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING
          }
        >
          {title}
        </Text>
      );
    }
    if (image) {
      return image;
    }
  };

  return (
    <TopContainer useAscentiLogo={useAscentiLogo}>
      {renderLeftButton()}
      <>{renderTitleOrImage()}</>
      {renderRightButton()}
    </TopContainer>
  );
};
