import { createApi } from '@reduxjs/toolkit/query/react';

import { ChatMessage, SuccessResponse } from 'shared-interfaces';
import { createBaseQuery } from '../baseQuery';

export interface ConversationResponse {
  userId: string;
  messages: ChatMessage[];
  total: number;
}

export interface ChatMessageRequest {
  message: string;
  tempId: string;
  createdAt: Date;
}

export interface UnreadCountResponse {
  unread: number;
}

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: createBaseQuery(),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<ChatMessage, ChatMessageRequest>({
      query: (body) => ({
        url: 'chat/messages',
        method: 'POST',
        body,
      }),
      transformResponse: (response: SuccessResponse<ChatMessage>) => response.data,
    }),
    chatGetConversation: builder.query<ConversationResponse, any>({
      query: (total) => ({
        url: `chat/messages?total=${total}`,
        method: 'GET',
      }),
      transformResponse: (response: SuccessResponse<ConversationResponse>) => response.data,
    }),
    chatMarkAsRead: builder.query<any, any>({
      query: () => ({
        url: 'chat/read',
        method: 'GET',
      }),
      transformResponse: (response: SuccessResponse<any>) => response.data,
    }),
  }),
});

export const { useChatGetConversationQuery, useChatMarkAsReadQuery, useSendMessageMutation } =
  chatApi;
