import styled from "styled-components";
import Footer from "../components/layout/Footer";
import useIsMobile from "../utilities/useIsMobile";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //margin: 30px 0;
  //padding-bottom: 60px;
  //padding-top: 60px;
`;

export const Assessment: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = useIsMobile();
  return (
    <Wrapper>
      <Content>{children}</Content>
      {!isMobile && <Footer />}
    </Wrapper>
  );
};
