import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useAssessmentCreateAccountDetailsMutation,
  getAssessmentAccountDetails,
  getReferralDetails,
  setAccountDetailsEmail,
  setAccountDetailsPassword,
  useAppSelector,
} from "shared-redux";
import {
  PRIVACY_POLICY_LINK,
  t,
  TERMS_AND_CONDITIONS_LINK,
  theme,
} from "shared-utilities";
import styled from "styled-components";
import { css } from "styled-components/macro";
import CloseButton from "../assets/svg/buttons/CloseButton";
import { DeleteAnswersModal } from "../components/assessment/DeleteAnswersModal";
import RoundButton from "../components/buttons/RoundButton";
import TextInputField from "../components/inputs/TextInputField";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";
import Form from "../components/patterns/Form";
import Paragraph from "../components/texts/Paragraph";
import { Assessment } from "./Assessment";

const disclaimerStyle = css({
  textAlign: "center",
  color: theme.palette.PRIMARY,
  marginBottom: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
});
const disclaimerBenefitStyle = css({
  // marginTop: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
});
const linkStyle = css({
  color: theme.palette.PRIMARY,
  // fontFamily: getFont({ name: "Omnes", weight: "medium" }),
});

const Text = styled.div({
  display: "inline",
});

const openLink = (url: string) => {
  window.open(url, "_blank");
};

const showTerms = () => openLink(TERMS_AND_CONDITIONS_LINK);
const showPrivacyPolicy = () => openLink(PRIVACY_POLICY_LINK);
export const SignUpScreen = () => {
  const navigate = useNavigate();

  // Store
  const dispatch = useDispatch();
  // const { paymentRequired } = useAppSelector(getReferralDetails) ?? {};
  const { email, password } = useAppSelector(getAssessmentAccountDetails);
  const dispatchSetEmail = (value: string) =>
    dispatch(setAccountDetailsEmail(value));
  const dispatchSetPassword = (value: string) =>
    dispatch(setAccountDetailsPassword(value));
  const referral = useAppSelector(getReferralDetails);
  const [dispatchCreateAccountDetails, { isLoading, error, isSuccess, reset }] =
    useAssessmentCreateAccountDetailsMutation();

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = useState<boolean>(false);

  // Form
  // const emailError = useValidator([EMAIL_VALIDATOR], email.trim());
  // const passwordError = useValidator([PASSWORD_VALIDATOR], password.trim());

  // Methods
  const openModalToggler = () => setIsOpen(!isOpen);
  const handleContinue = () => {
    dispatchCreateAccountDetails({
      email: email.trim(),
      password: password.trim(),
    });
  };

  const handleChangeEmailText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatchSetEmail(event.target.value);
    setDisplayErrors(false);
  };

  const handleChangePasswordText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatchSetPassword(event.target.value);
    setDisplayErrors(false);
  };

  // Computed Variables
  const isFormValid = Boolean(email && password);

  React.useEffect(() => {
    if (!error) return;
    console.log("error", error);
  }, [error]);

  React.useEffect(() => {
    if (!isSuccess) return;
    // TODO add check for payment required
    // if (paymentRequired) return
    navigate("/assessment-complete");
  }, [isSuccess]);

  React.useEffect(() => {
    return () => reset();
  }, []);

  return (
    <Assessment>
      <TopInfoBar
        title="Sign Up"
        rightButton={<CloseButton />}
        rightButtonOnClick={openModalToggler}
        useAscentiLogo
      />
      {isOpen && <DeleteAnswersModal onClose={openModalToggler} />}
      <Form title={t("assessment_medical_profile_txt")}>
        <TextInputField
          // ref={emailRef}
          style={{ marginBottom: theme.spacing(2) }}
          placeholderKey="form_label_email"
          value={email}
          // error={displayErrors && emailError}
          textContentType="emailAddress"
          autoCapitalize="none"
          returnKeyType="next"
          onChangeText={handleChangeEmailText}
          // onSubmitEditing={emailRef.current?.focus}
          editable={referral?.codeType !== "Unique"}
          // autoFocus
        />
        <TextInputField
          // ref={passwordRef}
          style={{ marginBottom: theme.spacing(2) }}
          placeholderKey="form_label_password"
          value={password}
          // error={displayErrors && passwordError}
          textContentType="newPassword"
          secureTextEntry
          autoCapitalize="none"
          returnKeyType="done"
          onChangeText={handleChangePasswordText}
        />
        <Text css={[theme.typography.Omnes.TINY_TEXT, disclaimerStyle]}>
          {t("account_disclaimer")}
          <Text css={linkStyle} onClick={showPrivacyPolicy}>
            {t("account_disclaimer_pc")}
          </Text>
          {t("account_disclaimer_between_links")}
          <Text css={linkStyle} onClick={showTerms}>
            {t("account_disclaimer_tos")}
          </Text>
        </Text>
        <RoundButton
          containerStyle={{ marginTop: theme.spacing(2) }}
          text={t("btn_signup")}
          onPress={handleContinue}
          disabled={!isFormValid}
          loading={isLoading}
        />
        <Paragraph
          size="tiny"
          text={t("account_disclaimer_benefit")}
          style={disclaimerBenefitStyle}
        />
      </Form>
    </Assessment>
  );
};
