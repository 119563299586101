import React, { useMemo } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { t, theme } from "shared-utilities";
import styled from "styled-components";
import { css, CSSProp, DefaultTheme } from "styled-components/macro";
import { View } from "../miscellaneous/View";
import ErrorText from "../texts/ErrorText";

const inputStyles = css({
  borderRadius: theme.utilities.BORDER_RADIUS,
  padding: 15,
});
const Root = styled.div({});
const MultiLineInput = styled(TextareaAutosize)`
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border: none;
  outline: none;
  background: transparent;
  &::placeholder {
    color: ${theme.palette.LIGHT_GREY};
  }
`;

const SingleLineInput = styled("input")`
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border: none;
  outline: none;
  background: transparent;
  &::placeholder {
    color: ${theme.palette.LIGHT_GREY};
  }
`;

interface TextInputFieldProps {
  placeholderKey: string;
  error?: string | false | null;
  inputStyle?: CSSProp<DefaultTheme>;
}

// interface TextInputFieldProps extends TextInputProps {
//   placeholderKey: string;
//   error?: string | false | null;
//   inputStyle?: TextInputProps['style'];
// }

const TextInputField = React.forwardRef<any, any>(
  (
    {
      onChangeText,
      onSubmitEditing,
      placeholderKey,
      placeholder,
      value,
      error,
      style,
      inputStyle,
      textContentType,
      secureTextEntry,
      autoCapitalize,
      returnKeyType,
      multiline = false,
      autoFocus,
      editable = true,
      readOnly = false,
    },
    ref
  ) => {
    // State
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    // Methods
    const toggleShowPassword = () => setShowPassword((v) => !v);

    // Computed Variables
    const backgroundColor =
      theme.palette[error && error !== "" ? "LIGHT_RED" : "BACKGROUND"];
    const animatedBackground = useMemo(
      () => ({
        backgroundColor,
      }),
      [backgroundColor]
    );

    const renderTextBox = () => {
      if (multiline) {
        return (
          <MultiLineInput
            placeholder={t(placeholderKey)}
            ref={ref}
            disabled={!editable}
            readOnly={readOnly}
            css={[
              theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT,
              inputStyles,
              animatedBackground,
              {
                height: multiline ? 100 * theme.utilities.SCALE : 52,
                color: editable ? theme.palette.PRIMARY : theme.palette.GREY,
              },
              inputStyle,
            ]}
            onChange={onChangeText}
            value={value}
          />
        );
      }
      return (
        <SingleLineInput
          placeholder={t(placeholderKey)}
          ref={ref}
          disabled={!editable}
          readOnly={readOnly}
          type={secureTextEntry ? "password" : "text"}
          css={[
            theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT,
            inputStyles,
            animatedBackground,
            {
              height: multiline ? 100 * theme.utilities.SCALE : 52,
              color: editable ? theme.palette.PRIMARY : theme.palette.GREY,
            },
            inputStyle,
          ]}
          onChange={onChangeText}
          value={value}
        />
      );
    };
    return (
      <Root css={[{ marginBottom: theme.spacing(2) }, style]}>
        <div style={{ marginBottom: theme.spacing(0.5) }}>
          {renderTextBox()}
          {error && (
            <View>
              <ErrorText text={error} marginBottom={0} />
            </View>
          )}
          {/* <AnimatedTextInput */}
          {/*  ref={ref} */}
          {/*  value={value} */}
          {/*  onChangeText={onChangeText} */}
          {/*  multiline={multiline} */}
          {/*  style={[ */}
          {/*    theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT, */}
          {/*    styles.input, */}
          {/*    animatedBackground, */}
          {/*    { */}
          {/*      height: multiline ? 100 * theme.utilities.SCALE : 52, */}
          {/*      color: editable */}
          {/*        ? theme.palette.PRIMARY */}
          {/*        : theme.palette.LIGHT_GREY, */}
          {/*    }, */}
          {/*    inputStyle, */}
          {/*  ]} */}
          {/*  placeholder={t(placeholderKey)} */}
          {/*  placeholderTextColor={theme.palette.LIGHT_GREY} */}
          {/*  underlineColorAndroid="transparent" */}
          {/*  textContentType={textContentType} */}
          {/*  secureTextEntry={secureTextEntry && !showPassword} */}
          {/*  autoCapitalize={autoCapitalize} */}
          {/*  returnKeyType={returnKeyType} */}
          {/*  onSubmitEditing={onSubmitEditing} */}
          {/*  autoFocus={autoFocus} */}
          {/*  editable={editable} */}
          {/* /> */}
          {/* <PasswordReveal */}
          {/*  visible={secureTextEntry} */}
          {/*  showPassword={showPassword} */}
          {/*  onPress={toggleShowPassword} */}
          {/* /> */}
        </div>
        {/* {error && ( */}
        {/*  <Animated.View */}
        {/*    entering={springify(FadeIn)} */}
        {/*    exiting={springify(FadeOut)} */}
        {/*  > */}
        {/*    <ErrorText text={error} marginBottom={0} /> */}
        {/*  </Animated.View> */}
        {/* )} */}
      </Root>
    );
  }
);

export default TextInputField;
