import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as FloorIcon } from "shared-assets/src/icons/Floor.svg";
import WorkoutImg from "shared-assets/src/Workout.png";
import { WorkoutDurationKey } from "shared-interfaces";
import {
  usePlanFetchQuery,
  useProgressFetchQuery,
  getWorkouts,
  useAppSelector,
} from "shared-redux";
import { t, theme, splitWorkoutIntoRounds } from "shared-utilities";
import { ScreenElement, Button } from "src/components";
import styled from "styled-components";
import LinkButton from "../components/buttons/LinkButton";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";
import Heading from "../components/texts/Heading";
import { Assessment } from "./Assessment";

const Wrapper = styled.div`
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Title = styled.p`
  font-family: ${theme.typography.FONT_NAME};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: #145e7f;
  margin: 70px 0 32px;
  width: 100%;
  max-width: 311px;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;

  img {
    width: 311px;
    height: 411px;
  }
`;

const Container = styled.div`
  width: 100%;
  z-index: 10;
  min-height: 411px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 279px;
    margin-bottom: 16px;
  }
`;

const ContainerTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #ffffff;
  max-width: 311px;
  margin: 31px 0 0;
`;

const ContainerSubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: #ffffff;
  max-width: 311px;
  margin: 18px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0 6px;
  }
`;

const BtnText = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

export const AssessmentFirstWorkoutScreen = () => {
  const navigate = useNavigate();
  const workouts = useAppSelector(getWorkouts);
  const [searchParams] = useSearchParams();
  const type = (searchParams.get("type") ?? "full") as WorkoutDurationKey;
  const workout = workouts[type];
  const rounds = useMemo(() => {
    if (!workout) return;
    return splitWorkoutIntoRounds(workout.workoutSummary);
  }, [workout]);

  const {
    isLoading: isLoadingPlan,
    error: errorPlan,
    refetch: resetPlan,
  } = usePlanFetchQuery();
  const {
    isLoading: isLoadingProgress,
    error: errorProgress,
    refetch: resetProgress,
  } = useProgressFetchQuery();

  const handleFirstWorkoutClick = () => {
    navigate("/workout/full/intro");
  };

  const handleHomeClick = () => {
    navigate("/");
  };
  return (
    <Assessment>
      <TopInfoBar useAscentiLogo />
      <div style={{ paddingTop: 60 }} />
      <ScreenElement>
        <Title>Here’s your first workout</Title>
        <Wrapper>
          <BackgroundWrapper>
            <img src={WorkoutImg} alt="commitment img" />
          </BackgroundWrapper>
          <Container>
            <div>
              <ContainerTitle>
                {workout
                  ? t("workout_estimate", {
                      time: Math.floor(workout.time / 60),
                    })
                  : 0}
                <br />
                {workout ? `${workout.exercises.length} exercises` : ""},{" "}
                {t("workout_rounds", {
                  rounds: rounds?.length ?? 0,
                }).toLocaleLowerCase()}
              </ContainerTitle>
              <ContainerSubTitle>
                All you need is <FloorIcon /> Floor space
              </ContainerSubTitle>
            </div>
            <Button
              handleClick={handleFirstWorkoutClick}
              bgColor={theme.palette.ACCENT_PRIMARY}
            >
              <BtnText
                css={[
                  theme.typography[theme.typography.FONT_NAME]
                    .REGULAR_BOLD_HEADING,
                ]}
              >
                {t("assessment_first_workout_do_workout_action")}
              </BtnText>
            </Button>
          </Container>
        </Wrapper>

        <LinkButton
          onPress={handleHomeClick}
          style={{ textAlign: "center", display: "flex", paddingTop: 16 }}
          // trackingEvent="Start Workout Later"
        >
          {t("assessment_first_workout_skip_action")}{" "}
          <Heading
            size="regular"
            bold
            style={{ paddingLeft: 4 }}
            text={t("assessment_first_workout_skip_btn")}
            color={theme.palette.VIBRANT_ORANGE}
          />
        </LinkButton>
      </ScreenElement>
    </Assessment>
  );
};
