import React, { CSSProperties } from "react";
import ReactPlayer from "react-player";
import Info from "shared-assets/src/svg/icons/Info";
import Mute from "shared-assets/src/svg/icons/Mute";
import Unmute from "shared-assets/src/svg/icons/Unmute";
import { WorkoutSummaryRow } from "shared-interfaces";
import { t, theme } from "shared-utilities";
import PauseButton from "src/assets/svg/buttons/PauseButton";
import PlayButton from "src/assets/svg/buttons/PlayButton";
import RoundButton from "src/components/buttons/RoundButton";
import Paper from "src/components/patterns/Paper";
import Heading from "src/components/texts/Heading";
import Stopwatch from "src/components/workout/Stopwatch";
import Timer from "src/components/workout/Timer";
import styled, { css } from "styled-components";
import { useWindowSize } from "usehooks-ts";
import CloseButton from "../assets/svg/buttons/CloseButton";
import { Button } from "../components";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";

// const Info = "div";
// const Mute = "div";
// const Unmute = "div";
// TODO: How to use it?
const getUrl = (url: string, cacheKey: string) => Promise.resolve(url);
// const getUrl = (url: string, cacheKey: string) =>
//   Promise.resolve("/video-ex.mp4");

const TouchableOpacity = styled.div<{ activeOpacity?: number }>`
  :active {
    opacity: ${(props) => props.activeOpacity || 1};
  }
`;
const View = "div";

const Root = styled.div`
  padding-top: 56px;
  flex: 1;
`;

const InSessionInstructionContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  justify-content: flex-end;
  align-items: center;
  padding-top: 56.25%;
`;

const OverlayInner = styled.div`
  position: absolute;
  top: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const VideoButton = styled.div`
  height: 52px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translate(-50%, 0);
`;

const InfoButtonContainer = styled.div`
  padding: 10px;
  float: right;
`;

const paperStyles = css`
  position: relative;
  flex: 1;
  border-radius: ${theme.utilities.BORDER_RADIUS}px;
  padding: 0px;
  box-shadow: none;
`;

interface ExercisePlayerProps {
  // navigation: any;
  exercise: WorkoutSummaryRow;
  onDone: (value: number) => void;
  onPause: () => void;
  onShowCancelModal: () => void;
  onShowDetails: (exercise: WorkoutSummaryRow) => void;
  onMute: () => void;
  isFocused?: boolean;
  muted: boolean;
  initialPaused: boolean;
  loading?: boolean;
}

const ExercisePlayer: React.FC<ExercisePlayerProps> = ({
  // navigation,
  exercise,
  onPause,
  onShowDetails,
  onShowCancelModal,
  onMute,
  onDone,
  isFocused,
  initialPaused,
  muted,
  loading,
}) => {
  // State
  const [accumulatedTime, setAccumulatedTime] = React.useState<number>(0);
  const [loopVideoUrl, setLoopVideoUrl] = React.useState<string | null>(null);
  const [paused, setPaused] = React.useState<boolean>(initialPaused);
  const [ended, setEnded] = React.useState<boolean>(false);

  // Methods
  const handleDone = () => {
    if (accumulatedTime === 0) return onDone(exercise.time);
    onDone(accumulatedTime);
  };

  const handlePlayPause = () => {
    if (ended) {
      setPaused(false);
      setEnded(false);
      return;
    }
    setPaused((v) => !v);
  };

  // Effects
  React.useEffect(() => {
    getUrl(exercise.exercise.loopVideoUrl, "loopVideo").then(setLoopVideoUrl);
  }, []);

  // Renderers
  const renderActionButton = (exercise: WorkoutSummaryRow) => {
    if (paused)
      return (
        <View
          style={{
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "15px",
          }}
        >
          <Button
            handleClick={onShowCancelModal}
            bgColor={theme.palette.BUTTON_PRIMARY}
            loading={loading}
            isFullWidth
          >
            {t("insession_player_exit_btn")}
          </Button>
        </View>
      );

    if (exercise.time === 0)
      return (
        <View
          style={{
            paddingLeft: "32px",
            paddingRight: "32px",
            paddingBottom: "15px",
          }}
        >
          <RoundButton
            text={t("btn_next")}
            onPress={handleDone}
            loading={loading}
          />
        </View>
      );

    return null;
  };

  const renderVideoOverlays = () => (
    <Overlay>
      <OverlayInner>
        <InfoButtonContainer onClick={() => onShowDetails(exercise)}>
          <Info />
        </InfoButtonContainer>
      </OverlayInner>
      <VideoButton onClick={handlePlayPause}>
        {paused ? <PlayButton size={52} /> : <PauseButton size={52} />}
      </VideoButton>
    </Overlay>
  );

  const renderTimer = (time: number) => (
    <Timer
      seconds={time}
      paused={paused}
      onFinish={handleDone}
      fontSize="biggest"
    />
  );

  const renderInstructions = (exercise: WorkoutSummaryRow) => {
    const renderInner = () => {
      if (exercise.exercise.massage !== 0)
        return (
          <>
            <Heading
              size="larger"
              text={t("insession_player_instructions_massage")}
            />
            {renderTimer(exercise.time)}
          </>
        );

      if (exercise.hold !== 0) {
        return (
          <>
            <Heading
              size="larger"
              text={t("insession_player_instructions_hold")}
            />
            {renderTimer(exercise.time)}
          </>
        );
      }

      if (exercise.repetitions < 2 && exercise.time > 0) {
        return (
          <>
            <Heading
              size="larger"
              text={t("insession_player_instructions_repeat")}
            />
            {renderTimer(exercise.time)}
          </>
        );
      }

      return (
        <>
          <Heading
            size="larger"
            text={t("insession_player_instructions_reps")}
          />
          <Heading
            size="bigger"
            text={t("insession_player_instructions_reps_times", {
              reps: exercise.repetitions,
            })}
          />
          <Stopwatch paused={paused} updateSeconds={setAccumulatedTime} />
        </>
      );
    };

    return (
      <InSessionInstructionContainer
        style={{
          opacity: paused ? 0.3 : 1,
        }}
      >
        {renderInner()}
      </InSessionInstructionContainer>
    );
  };

  const { width } = useWindowSize();

  return (
    <Root>
      <TopInfoBar
        title={t((exercise.exercise as any).nameId)}
        leftButton={
          <TouchableOpacity
            activeOpacity={0.8}
            onClick={onMute}
            // style={styles.muteButton}
          >
            {muted ? <Unmute /> : <Mute />}
          </TouchableOpacity>
        }
        rightButton={<CloseButton />}
        rightButtonOnClick={onShowCancelModal}
      />
      <Paper style={paperStyles}>
        {/* TODO handle max width */}
        <div
          style={{
            height: width / 1.78082191781,
            width: `${width}px`,
            backgroundColor: theme.palette.LIGHTEST_GREY,
          }}
        >
          <ReactPlayer
            url={loopVideoUrl ?? ""}
            width="100%"
            height="100%"
            playing={!paused}
            onEnded={() => onPause()}
            playsinline
            loop
          />
        </div>
        {renderInstructions(exercise)}
        {renderVideoOverlays()}
      </Paper>
      {renderActionButton(exercise)}
    </Root>
  );
};

export default ExercisePlayer;
