/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { TextQuestion } from "shared-interfaces";
import { theme } from "shared-utilities";
import styled from "styled-components/macro";
import EmphasizedText from "../../texts/EmphasizedText";
import Heading from "../../texts/Heading";
import Subtitle from "../../texts/Subtitle";

const LongTextInput = styled.textarea`
  width: 100%;
  height: 98px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${theme.palette.PRIMARY};
  border-radius: 4px;
  background: #e7eef2;
  border: none;
  box-sizing: border-box;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
`;

interface LongTextProps {
  item: TextQuestion;
  answers?: any;
  onChange: (id: number, type: string, value: string) => void;
}

const LongTextQuestionView: React.FC<LongTextProps> = ({
  item,
  answers,
  onChange,
}) => {
  const selectedQuestionAnswers = answers.find(
    (answer: any) => answer?.question === item.id
  );

  const [style, setStyle] = React.useState<React.CSSProperties>({
    transform: "translateY(0)",
  });

  const changeValueHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(item.id, item.type, e.target.value);
  };

  const handleFocus = () => {
    if (/Android/i.test(navigator.userAgent)) {
      setStyle({
        transform: "translateY(-25vh)",
        transition: ".3s ease",
      });
    }
  };

  const handleBlur = () => {
    if (/Android/i.test(navigator.userAgent)) {
      setStyle({
        transform: "translateY(0)",
        transition: ".3s ease",
      });
    }
  };

  return (
    <div style={style}>
      <Heading text={item.title} size="medium" />
      <EmphasizedText text={item.emphasised} />
      <Subtitle text={item.subtitle} />
      <LongTextInput
        css={theme.typography[theme.typography.FONT_NAME].REGULAR_TEXT}
        onChange={changeValueHandler}
        onFocus={handleFocus}
        onBlur={handleBlur}
        defaultValue={selectedQuestionAnswers?.input?.value}
      />
    </div>
  );
};

export default LongTextQuestionView;
