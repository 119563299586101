import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReferralDetails, useAppSelector } from "shared-redux";
import { theme } from "shared-utilities";
import {
  Button,
  InstructionCard,
  InstructionModal,
  ScreenElement,
} from "src/components";
import styled from "styled-components/macro";
import { TopInfoBar } from "../components/layout/navigation/TopInfoBar";

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
`;

const Title = styled.h1`
  padding-top: 34px;
  padding-bottom: 4px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${theme.palette.PRIMARY};
  margin: 0;
  max-width: 300px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${theme.palette.PRIMARY};
  margin: 0 0 6px;
  max-width: 260px;
`;

const ModalLink = styled.button`
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #80918c;
`;
const Text = styled.div``;

const Logo = styled.img`
  margin: 1rem auto;
  height: 34px;
`;

export const InstructionsOfUseQuestionScreenWpa = () => {
  const navigate = useNavigate();
  const referralDetails = useAppSelector(getReferralDetails);
  console.log("re", referralDetails?.organisation);

  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    privacy: false,
    instructions: false,
    wpaTerms: false,
  });
  const [logo, fetchLogo] = React.useState<JSX.Element | null>(null);

  const isDisabled = !Object.values(values).every((value) => value);
  const navigateToQuestionsScreen = () => navigate("/questions");

  const modalToggler = () => setIsOpen(!isOpen);

  const updateValuesHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
  };

  useEffect(() => {
    import("shared-assets/src/themes/defaultTheme/icons/logo.svg").then(
      (res) => {
        fetchLogo(<Logo src={res.default} />);
      }
    );
  }, []);

  return (
    <ScreenElement>
      <Wrapper>
        <TopInfoBar image={logo} />;<Title>Service provided by Ascenti</Title>
        <Subtitle>Before continuing, please confirm the following:</Subtitle>
        <InstructionCard
          id="1"
          name="instructions"
          value={values.instructions}
          label={`You have read and agree to our <a style="color: ${theme.palette.ACCENT_PRIMARY}" href="https://www.ascenti.co.uk/reach/instructions-for-use">instructions for use</a>. These are designed to help you safely use the app and avoid making your injury or condition worse.`}
          onChange={updateValuesHandler}
        />
        <InstructionCard
          id="2"
          name="privacy"
          value={values.privacy}
          label={`You consent to us processing your data as per our <a style="color: ${theme.palette.ACCENT_PRIMARY}" href="https://www.ascenti.co.uk/privacy" >privacy statement</a>. This allows us to make automated decisions to give you the best possible in-app experience.`}
          onChange={updateValuesHandler}
        />
        <InstructionCard
          id="3"
          name="wpaTerms"
          value={values.wpaTerms}
          label={`You have read and agree to our <a style="color: ${theme.palette.ACCENT_PRIMARY}" href="https://www.ascenti.co.uk/wpa-terms-conditions" >terms and conditions</a>`}
          onChange={updateValuesHandler}
        />
        <ModalLink onClick={modalToggler}>
          I’d like to contact someone
        </ModalLink>
      </Wrapper>
      <div style={{ paddingTop: "54px" }} />
      <Footer>
        <Button
          bgColor={theme.palette.BUTTON_PRIMARY}
          isFullWidth
          isRoundedCorners={false}
          isActive={!isDisabled}
          handleClick={navigateToQuestionsScreen}
        >
          <Text
            css={[
              theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
              { color: theme.palette.WHITE },
            ]}
          >
            Confirm & Continue
          </Text>
        </Button>
      </Footer>
      {isOpen && <InstructionModal closeModal={modalToggler} />}
    </ScreenElement>
  );
};
